import { IcAlert, IcBreadcrumb, IcBreadcrumbGroup, IcPageHeader } from "@ukic/react";
import { bool } from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import CertificateListView from "../../components/ListView/CertificateListView";
import PageNavigation from "../../components/ListView/PageNavigation";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { countCertificates, queryCertificates } from "../../utils/axiosRequests";

const CertificateRelationshipPage = ({ getChild }) => {
  const { id } = useParams();
  const { token } = useAuthorisationToken();
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCount, setLoadingCount] = useState(true);
  const title = getChild ? "Child" : "Issuing";
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 100;
  const [documentCount, setDocumentCount] = useState(0);

  const getChildren = async (payload) => {
    await queryCertificates(payload, token)
      .then((response) => {
        setCertificates(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "Cancelled Error") {
          setError(`${e.name}. ${e.message}`);
          setLoading(false);
        }
      });
  };

  const getChildrenCount = async (requestPayload) => {
    await countCertificates(requestPayload, token)
      .then((response) => {
        setDocumentCount(response.data);
        setLoadingCount(false);
      })
      .catch((e) => {
        if (e.name !== "Cancelled Error") {
          setLoadingCount(false);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    const payload = {
      filterParams: {
        childOfId: getChild ? id : null,
        issuerOfId: !getChild ? id : null,
      },
      formatParams: {
        formatCertificate: "SUMMARY",
      },
      pageParams: {
        limit: LIMIT,
        offset: currentPage - 1,
      },
    };

    getChildren(payload);
    getChildrenCount(payload);
  }, [currentPage]);

  if (loading || loadingCount) {
    return <Loading />;
  }

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header selected="certificates" />
      <IcPageHeader heading={`${documentCount} ${title} Certificate${documentCount !== 1 ? "s" : ""} Found`}>
        <IcBreadcrumbGroup slot="breadcrumbs">
          <IcBreadcrumb pageTitle="Certificates" href="/certificates" />
          <IcBreadcrumb pageTitle="Details" href={"/certificates/" + id} />
          {getChild ? (
            <IcBreadcrumb current pageTitle="Children" href={"/certificates/" + id + "/children"} />
          ) : (
            <IcBreadcrumb current pageTitle="Issuers" href={"/certificates/" + id + "/issuers"} />
          )}
        </IcBreadcrumbGroup>
        {error && <IcAlert heading="Warning" message={error} variant="error" slot="subheading" />}
      </IcPageHeader>
      {documentCount !== 0 && (
        <PageNavigation
          currentPage={currentPage}
          pageCount={Number(Math.ceil(documentCount / LIMIT))}
          onPageChange={setCurrentPage}
        />
      )}
      <CertificateListView certificates={certificates} />
      {documentCount !== 0 && (
        <PageNavigation
          currentPage={currentPage}
          pageCount={Number(Math.ceil(documentCount / LIMIT))}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

CertificateRelationshipPage.propTypes = {
  getChild: bool,
};

export default CertificateRelationshipPage;

import { IcSelect, IcTextField } from "@ukic/react";
import { bool, func, shape, string } from "prop-types";
import { useEffect, useState } from "react";

const PublicKeyFilters = ({ setPayload }) => {
  const [filters, setFilters] = useState(undefined);
  const debounce = 500;

  useEffect(() => {
    // Set the payload in the parent
    setPayload(filters);
  }, [filters]);

  const setAlgorithmName = (value) => {
    // Reset filters if we change the algorithm name
    setFilters({
      algorithmName: value,
    });
  };

  const setFiltersField = (field, value) => {
    setFilters((prevFormData) => ({
      ...prevFormData,
      [field]: value !== "" ? value : null,
    }));
  };

  return (
    <>
      <IcSelect
        label="Public Key Algorithm"
        options={[
          { label: "RSA", value: "RSA" },
          { label: "Elliptic Curve", value: "ECDSA" },
          { label: "Other", value: "other" },
        ]}
        showClearButton
        onIcChange={(event) => setAlgorithmName(event.detail.value)}
      />
      {filters?.algorithmName === "other" && (
        <IcTextField
          label="Public Key Algorithm OID"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("algorithmOid", event.detail.value)}
        />
      )}
      {filters?.algorithmName === "ECDSA" && (
        <IcTextField
          label="Public Key Algorithm Params"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("algorithmParams", event.detail.value)}
        />
      )}
      {filters?.algorithmName === "RSA" && (
        <>
          <IcTextField
            label="Modulus"
            debounce={debounce}
            onIcChange={(event) => setFiltersField("modulus", event.detail.value)}
          />
          <IcTextField
            label="Exponent"
            debounce={debounce}
            onIcChange={(event) => setFiltersField("publicExponent", event.detail.value)}
          />
        </>
      )}
      {filters?.algorithmName !== "RSA" && (
        <IcTextField
          label="Data"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("data", event.detail.value)}
        />
      )}
    </>
  );
};

PublicKeyFilters.propTypes = {
  initialValue: shape({
    algorithmName: string,
    algorithmOid: string,
    algorithmParams: string,
    modulus: string,
    publicExponent: string,
    data: string,
  }),
  expanded: bool,
  setPayload: func.isRequired,
};

export default PublicKeyFilters;

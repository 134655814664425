import "./IngestPage.css";

import { IcAlert, IcDataRow, IcPageHeader } from "@ukic/react";
import { useEffect, useState } from "react";

import Error from "../../components/Error/Error";
import Header from "../../components/Header/Header";
import ListViewItem from "../../components/ListView/ListViewItem";
import PageNavigation from "../../components/ListView/PageNavigation";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { queryIngestPipeline } from "../../utils/axiosRequests";
import IngestStatus from "./IngestStatus";

const IngestPage = () => {
  const { token } = useAuthorisationToken();
  const [statuses, setStatuses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatuses, setCurrentStatuses] = useState([]);
  const PAGE_LIMIT = 100;

  const getStatuses = async () => {
    await queryIngestPipeline(token)
      .then((data) => {
        setStatuses(data);
        setError(null);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setCurrentStatuses(statuses.slice((currentPage - 1) * PAGE_LIMIT, currentPage * PAGE_LIMIT));
    setPageCount(Math.ceil(statuses.length / PAGE_LIMIT));
  }, [statuses, currentPage]);

  useEffect(() => {
    getStatuses();
  }, []);

  if (loading) {
    return <Loading />;
  }

  const ingestPageContents = (statuses) => {
    if (statuses === null || statuses.length === 0) {
      return <Error heading="No Ingest Pipeline exections found" />;
    }

    return statuses.map((execution, idx) => (
      <ListViewItem
        headingPrefix={"ID: "}
        id_={`${execution.id}`}
        url={`/ingest/${execution.id}`}
        key={`execution${idx}`}
        withNewTabButton={false}
      >
        <IcDataRow label="Started" value={execution.started} />
        <IcDataRow label="Status">
          <IngestStatus slot="value" status={execution.status} />
        </IcDataRow>
      </ListViewItem>
    ));
  };

  return (
    <div className="ingest-page">
      <Header selected="ingest" />
      <IcPageHeader heading={`${statuses.length} Recent Execution(s) Found`}>
        {error && <IcAlert heading="Warning" message={error} variant="error" slot="stepper" />}
      </IcPageHeader>
      {ingestPageContents(currentStatuses)}
      {statuses.length > 0 && (
        <PageNavigation pageCount={pageCount} onPageChange={setCurrentPage} currentPage={currentPage} />
      )}
    </div>
  );
};

export default IngestPage;

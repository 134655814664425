import "./PageNavigation.css";

import { IcPagination } from "@ukic/react";
import { func, number } from "prop-types";

const PageNavigation = ({ pageCount, onPageChange, currentPage }) => {
  return (
    <IcPagination
      className="page-nav"
      currentPage={currentPage}
      pages={pageCount}
      type={pageCount == 1 ? "simple" : "complex"}
      onIcPageChange={(icPage) => {
        onPageChange(icPage.detail.value);
      }}
    />
  );
};

PageNavigation.propTypes = {
  pageCount: number.isRequired,
  onPageChange: func.isRequired,
  currentPage: number.isRequired,
};

export default PageNavigation;

class NetworkError extends Error {
  constructor(message, options) {
    super(message, options);
    this.name = "Network Error";
  }
}

class CancelledError extends Error {
  constructor(options) {
    super(options);
    this.name = "Cancelled Error";
  }
}

class NotFoundError extends Error {
  constructor(message, options) {
    super(message, options);
    this.name = "Not Found";
  }
}

class InternalServerError extends Error {
  constructor(message, options) {
    super(message, options);
    this.name = "Internal Server Error";
  }
}

class UnprocessableEntity extends Error {
  constructor(message, options) {
    super(message, options);
    this.name = "Unprocessable Entity";
  }
}

export { CancelledError, InternalServerError, NetworkError, NotFoundError, UnprocessableEntity };

import { IcNavigationButton, IcNavigationItem, IcTopNavigation, SlottedSVG } from "@ukic/react";
import { oneOf } from "prop-types";

import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import config from "../../utils/config";

const Header = ({ selected }) => {
  const { removeToken } = useAuthorisationToken();
  return (
    <>
      <IcTopNavigation appTitle="Certificate Store" status={config.environment} version={config.version}>
        <IcNavigationButton label="Swagger UI" slot="buttons" href="/api">
          <SlottedSVG
            slot="icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M15,4V6H18V18H15V20H20V4M4,4V20H9V18H6V6H9V4H4Z" />
          </SlottedSVG>
        </IcNavigationButton>
        <IcNavigationButton label="Logout" slot="buttons" onClick={() => removeToken()}>
          <SlottedSVG
            slot="icon"
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M17 8L15.6 9.4L17.2 11H9V13H17.2L15.6 14.6L17 16L21 12L17 8M5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z" />
          </SlottedSVG>
        </IcNavigationButton>
        <IcNavigationItem slot="navigation" label="Home" href="/" selected={selected === "home"} />
        <IcNavigationItem
          slot="navigation"
          label="Certificates"
          href="/certificates"
          selected={selected === "certificates"}
        />
        <IcNavigationItem slot="navigation" label="Keys" href="/keys" selected={selected === "keys"} />
        <IcNavigationItem slot="navigation" label="Ingest" href="/ingest" selected={selected === "ingest"} />
      </IcTopNavigation>
    </>
  );
};

Header.propTypes = {
  selected: oneOf(["home", "certificates", "keys", "ingest"]).isRequired,
};

export default Header;

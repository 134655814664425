import { IcButton, IcDataEntity, IcDataRow, IcLink, IcSectionContainer, IcTypography } from "@ukic/react";
import { shape } from "prop-types";
import { useNavigate } from "react-router-dom";

import { publicKeyCore, publicKeySubject } from "../../types";
import HorizontalContainer from "../HorizontalContainer/HorizontalContainer";
import IcDataRowHexValue from "./IcDataRowHexValue";

const KeyForm = ({ pubKey }) => {
  const navigate = useNavigate();

  const certificateLink = (id) => {
    return (
      <>
        <IcLink href={"/certificates/" + id}>{id}</IcLink>
        <br />
      </>
    );
  };

  const displayCount = (count) => {
    if (!count) {
      return "";
    } else if (count.length >= 10) {
      return `(${count.length}+)`;
    } else {
      return `(${count.length})`;
    }
  };

  return (
    <IcSectionContainer>
      <IcDataEntity heading="Summary">
        <IcDataRow label="ID" value={pubKey.id} />
        <KeySubForm pubKey={pubKey} />
        <IcDataRow label={`Certificates ${displayCount(pubKey.certificateIds)}`}>
          <HorizontalContainer slot="value">
            <IcTypography variant="body" slot="value">
              {pubKey.certificateIds && pubKey.certificateIds.length > 0
                ? pubKey.certificateIds.map((certificate, idx) => {
                    return <div key={`cert${idx}`}>{certificateLink(certificate)}</div>;
                  })
                : "None"}
            </IcTypography>
            {!!pubKey.certificateIds && (
              <IcButton
                variant="primary"
                onClick={() => navigate(`/keys/${pubKey.id}/certificates`)}
                className="button"
                slot="value"
              >
                More Info
              </IcButton>
            )}
          </HorizontalContainer>
        </IcDataRow>
      </IcDataEntity>
    </IcSectionContainer>
  );
};

KeyForm.propTypes = {
  pubKey: shape(publicKeyCore).isRequired,
};

const KeySubForm = ({ pubKey }) => {
  return (
    <>
      <IcDataRow label="Algorithm Name" value={pubKey.algorithm.name} />
      {pubKey?.algorithm?.params && <IcDataRow label="Algorithm Params" value={pubKey.algorithm.params} />}
      {pubKey?.publicKey?.modulus && <IcDataRowHexValue label="Modulus" value={pubKey.publicKey.modulus} />}
      {pubKey?.publicKey?.publicExponent && (
        <IcDataRowHexValue label="Exponent" value={pubKey.publicKey.publicExponent} />
      )}
      {pubKey?.publicKey?.data && <IcDataRowHexValue label="Data" value={pubKey.publicKey.data} />}
    </>
  );
};

KeySubForm.propTypes = {
  pubKey: shape(publicKeySubject).isRequired,
};

export { KeyForm, KeySubForm };

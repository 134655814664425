import {
  IcAlert,
  IcButton,
  IcDataEntity,
  IcDataRow,
  IcSectionContainer,
  IcTab,
  IcTabContext,
  IcTabGroup,
  IcTabPanel,
} from "@ukic/react";
import { IcBreadcrumb, IcBreadcrumbGroup, IcPageHeader } from "@ukic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Error from "../../components/Error/Error";
import { KeyForm } from "../../components/Form/KeyForm";
import Header from "../../components/Header/Header";
import HorizontalContainer from "../../components/HorizontalContainer/HorizontalContainer";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { getKeyById } from "../../utils/axiosRequests";
import { getEncodedKeyById } from "../../utils/axiosRequests";
import { NotFoundError } from "../../utils/errors";

const KeyDetailsPage = () => {
  const { token } = useAuthorisationToken();
  const { id } = useParams();
  const [key, setKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [encodedKey, setEncodedKey] = useState(null);
  const [encodedKeyFormat, setEncodedKeyFormat] = useState("BASE_64");

  const getKey = async () => {
    setLoading(true);
    await getKeyById(id, token)
      .then((data) => setKey(data))
      .catch((e) => {
        if (!(e instanceof NotFoundError)) {
          setError(`${e.name}. ${e.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getEncodedKey = async () => {
    await getEncodedKeyById(id, encodedKeyFormat, token)
      .then((data) => setEncodedKey(data))
      .catch((e) => {
        if (!(e instanceof NotFoundError)) {
          setError(`${e.name}. ${e.message}`);
        }
      });
  };

  const download = async (filename) => {
    const blob = new Blob([encodedKey], { type: "text/plain" });
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(blob);
    anchor.download = filename;
    anchor.click();
    anchor.remove();
    URL.revokeObjectURL(anchor.href);
  };

  useEffect(() => {
    getKey();
    getEncodedKey();
  }, [id]);

  useEffect(() => {
    getEncodedKey();
  }, [encodedKeyFormat]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header selected="keys" />
      {error && <IcAlert heading="Warning" message={error} variant="error" style={{ margin: "16px 24px" }} />}
      <IcPageHeader heading={`Key Details`} reverseOrder>
        <IcBreadcrumbGroup slot="breadcrumbs">
          <IcBreadcrumb pageTitle="Keys" href="/keys" />
          <IcBreadcrumb pageTitle="Query" href="/keys/query" />
          <IcBreadcrumb current pageTitle="Details" href={"/keys/" + id} />
        </IcBreadcrumbGroup>
      </IcPageHeader>
      <IcTabContext>
        <IcTabGroup label="Tab Group">
          <IcTab>Information</IcTab>
          <IcTab>Encoded</IcTab>
        </IcTabGroup>
        <IcTabPanel>
          {key ? (
            <KeyForm pubKey={key} />
          ) : (
            <Error heading="Key not found" subheading={`The Key with ID: ${id} does not exist.`} />
          )}
        </IcTabPanel>
        <IcTabPanel>
          <IcSectionContainer>
            <HorizontalContainer>
              <IcButton
                variant="primary"
                onClick={() => setEncodedKeyFormat(encodedKeyFormat === "BASE_64" ? "DER" : "BASE_64")}
                className="button"
              >
                {`Switch to ${encodedKeyFormat === "BASE_64" ? "DER" : "BASE 64"} encoding`}
              </IcButton>
              <IcButton
                variant="secondary"
                onClick={() => (encodedKeyFormat === "BASE_64" ? download(`${id}.pem`) : download(`${id}.txt`))}
                className="button"
              >
                Download
              </IcButton>
            </HorizontalContainer>
            <IcDataEntity>
              <IcDataRow>
                <div className="monospace" slot="value">
                  {encodedKey}
                </div>
              </IcDataRow>
            </IcDataEntity>
          </IcSectionContainer>
        </IcTabPanel>
      </IcTabContext>
    </div>
  );
};

export default KeyDetailsPage;

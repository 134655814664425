import { IcEmptyState, SlottedSVG } from "@ukic/react";
import { string } from "prop-types";

const Error = ({ heading, subheading }) => {
  return (
    <IcEmptyState heading={heading} subheading={subheading} style={{ marginTop: "40px" }} aligned="center">
      <SlottedSVG slot="image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#D07932">
        <title id="warning-title">Warning</title>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
      </SlottedSVG>
    </IcEmptyState>
  );
};

Error.propTypes = {
  heading: string,
  subheading: string,
};

export default Error;

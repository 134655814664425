import "./ListViewItem.css";

import { IcButton, IcCard, IcDataEntity, IcLink, IcTypography, SlottedSVG } from "@ukic/react";
import { bool, node, string } from "prop-types";

const ListViewItem = ({ headingPrefix, id_, children, url, withNewTabButton = true }) => {
  return (
    <IcCard className="list-view-item-card" fullWidth>
      <div slot="heading" className="list-view-item-header">
        <IcTypography variant="h3" data-testid="list-view-item-id">
          {headingPrefix}
          <IcLink href={url}>{id_}</IcLink>
        </IcTypography>
        {withNewTabButton && (
          <IcButton
            aria-label="Open in new tab"
            variant="icon"
            onClick={() => window.open(url, "_blank")}
            className="list-view-item-new-tab-button"
          >
            <SlottedSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
            </SlottedSVG>
            {/* </IcTooltip> */}
          </IcButton>
        )}
      </div>
      <IcDataEntity slot="message" className="list-view-item-body">
        {children}
      </IcDataEntity>
    </IcCard>
  );
};

ListViewItem.propTypes = {
  headingPrefix: string.isRequired,
  id_: string.isRequired,
  children: node.isRequired,
  url: string.isRequired,
  withNewTabButton: bool,
};

export default ListViewItem;

import { IcCheckbox, IcCheckboxGroup, IcTextField } from "@ukic/react";
import { func } from "prop-types";
import { useEffect, useState } from "react";

const ExtensionFilters = ({ setFiltersField }) => {
  const [selectedExtensions, setSelectedExtensions] = useState([]);
  const debounce = 500;

  const updateCheckboxes = (detail) => {
    if (!detail.selectedOption.checked) {
      setSelectedExtensions((prevSelected) => prevSelected.filter((ext) => ext.name !== detail.selectedOption.value));
      return;
    }

    setSelectedExtensions((prevSelected) => [
      ...prevSelected,
      {
        name: detail.selectedOption.value,
        value: null,
      },
    ]);
  };

  const updateCheckboxField = (name, value) => {
    setSelectedExtensions((prevSelected) =>
      prevSelected.map((ext) => (ext.name === name ? { ...ext, value: value !== "" ? value : null } : ext)),
    );
  };

  const isExtensionSelected = (name) => {
    return selectedExtensions.some((ext) => ext.name === name);
  };

  useEffect(() => {
    setFiltersField("extensions", selectedExtensions.length !== 0 ? selectedExtensions : undefined);
  }, [selectedExtensions]);

  const extensionValueField = (value, label) => {
    return (
      <>
        <IcCheckbox value={value} label={label} />
        {isExtensionSelected(value) && (
          <>
            <IcTextField
              data-testid={`${value}-value`}
              placeholder={"Value (Optional)"}
              size="small"
              label=""
              debounce={debounce}
              onIcChange={(event) => updateCheckboxField(value, event.detail.value)}
            />
            <hr />
          </>
        )}
      </>
    );
  };

  return (
    <IcCheckboxGroup
      label="Contains Extension(s)"
      name="default"
      onIcChange={(event) => updateCheckboxes(event.detail)}
    >
      {extensionValueField("subjectDirectoryAttributes", "Subject Directory Attributes")}
      {extensionValueField("subjectKeyIdentifier", "Subject Key Identifier")}
      {extensionValueField("keyUsage", "Key Usage")}
      {extensionValueField("subjectAltName", "Subject Alt Name")}
      {extensionValueField("issuerAltName", "Issuer Alt Name")}
      {extensionValueField("basicConstraints", "Basic Constraints")}
      {extensionValueField("nameConstraints", "Name Constraints")}
      {extensionValueField("cRLDistributionPoints", "CRL Distribution Points")}
      {extensionValueField("certificatePolicies", "Certificate Policies")}
      {extensionValueField("policyMappings", "Policy Mappings")}
      {extensionValueField("authorityKeyIdentifier", "Authority Key Identifier")}
      {extensionValueField("policyConstraints", "Policy Constraints")}
      {extensionValueField("extendedKeyUsage", "Extended Key Usage")}
      {extensionValueField("freshestCRL", "Freshest CRL")}
      {extensionValueField("inhibitAnyPolicy", "Inhibit Any Policy")}
      {extensionValueField("authorityInfoAccess", "Authority Info Access")}
      {extensionValueField("subjectInfoAccess", "Subject Info Access")}
      {extensionValueField("signedCertificateTimestamps", "Signed Certificate Timestamps")}
      {extensionValueField("precertificateSignedCertificateTimestamps", "Pre-Certificate SCTs")}
    </IcCheckboxGroup>
  );
};

ExtensionFilters.propTypes = {
  setFiltersField: func.isRequired,
};

export default ExtensionFilters;

import "./CertificateQueryPage.css";

import { IcAlert, IcLoadingIndicator, IcPageHeader } from "@ukic/react";
import { useEffect, useState } from "react";

import CertificateFilters from "../../components/Filter/CertificateFilters";
import Header from "../../components/Header/Header";
import CertificateListView from "../../components/ListView/CertificateListView";
import PageNavigation from "../../components/ListView/PageNavigation";
import Loading from "../../components/Loading/Loading";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { countCertificates, queryCertificates } from "../../utils/axiosRequests";

const CertificateQueryPage = () => {
  const { token } = useAuthorisationToken();
  const [filterParams, setFilterParams] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [documentCount, setDocumentCount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingCerts, setLoadingCerts] = useState(true);
  const [loadingCount, setLoadingCount] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const LIMIT = 100;

  const buildRequest = () => {
    return {
      filterParams: filterParams,
      formatParams: {
        formatCertificate: "SUMMARY",
      },
      pageParams: {
        limit: LIMIT,
        offset: currentPage - 1,
      },
    };
  };

  const getCertificates = async () => {
    setLoadingCerts(true);
    await queryCertificates(buildRequest(), token)
      .then((response) => {
        setCertificates(response.data);
        setError(null);
        setLoading(false);
        setLoadingCerts(false);
      })
      .catch((e) => {
        if (e.name !== "Cancelled Error") {
          setCertificates([]);
          setError(`${e.name}. ${e.message}`);
          setLoading(false);
          setLoadingCerts(false);
        }
      });
  };

  const getCertificateCount = async () => {
    setLoadingCount(true);
    await countCertificates(buildRequest(), token)
      .then((response) => {
        setDocumentCount(response.data);
        setLoadingCount(false);
      })
      .catch((e) => {
        if (e.name !== "Cancelled Error") {
          setDocumentCount(null);
          setLoadingCount(false);
        }
      });
  };

  const countHeading = () => {
    if (loadingCount || documentCount === null) {
      // Count hasn't loaded yet, estimate one.
      if (certificates.length >= LIMIT) {
        return `${certificates.length}+ Certificates Found`;
      } else {
        return `${certificates.length} Certificate${certificates.length !== 1 ? "s" : ""} Found`;
      }
    }

    return `${documentCount} Certificate${documentCount !== 1 ? "s" : ""} Found`;
  };

  useEffect(() => {
    // Reset to page 1
    setCurrentPage(1);
  }, [filterParams]);

  useEffect(() => {
    getCertificates();
    window.scrollTo(0, 0);
  }, [filterParams, currentPage]);

  useEffect(() => {
    getCertificateCount();
  }, [filterParams]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="certificate-query-page">
      <Header selected="certificates" />
      <div className="page-content-container">
        <div className="sidebar-area">
          <Sidebar>
            <CertificateFilters setPayload={setFilterParams} />
          </Sidebar>
        </div>
        <div className="main-area" style={{ marginBottom: "24px" }}>
          {loadingCerts ? (
            <Loading inline />
          ) : (
            <>
              <IcPageHeader heading={countHeading()}>
                {loadingCount && (
                  <IcLoadingIndicator size="small" label=" " slot="heading-adornment" style={{ marginTop: "4px" }} />
                )}
                {error && <IcAlert heading="Warning" message={error} variant="error" slot="stepper" />}
              </IcPageHeader>
              {documentCount !== 0 && !loadingCount && (
                <PageNavigation
                  currentPage={currentPage}
                  pageCount={Number(Math.ceil(documentCount / LIMIT))}
                  onPageChange={setCurrentPage}
                />
              )}
              <CertificateListView certificates={certificates} />
              {documentCount !== 0 && !loadingCount && (
                <PageNavigation
                  currentPage={currentPage}
                  pageCount={Number(Math.ceil(documentCount / LIMIT))}
                  onPageChange={setCurrentPage}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CertificateQueryPage;

import { IcBackToTop, IcClassificationBanner } from "@ukic/react";

import config from "../../utils/config";

const Footer = () => {
  return (
    <>
      <IcClassificationBanner classification={config.classification} country="UK" />
      <IcBackToTop target="main" />
    </>
  );
};

export default Footer;

import "react-calendar/dist/Calendar.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "./DateTimeField.css";

import { IcInputComponentContainer, IcInputContainer, IcInputLabel, IcTooltip, SlottedSVG } from "@ukic/react";
import { func, shape, string } from "prop-types";
import { useEffect, useState } from "react";
import { DateTimePicker } from "react-datetime-picker";

import HorizontalContainer from "../HorizontalContainer/HorizontalContainer";

const DateTimeField = ({ value, label, tooltip, onChange }) => {
  const [dateTime, setDateTime] = useState(null);

  useEffect(() => {
    setDateTime(toDateTime(value?.start));
  }, [value]);

  const toDateTime = (value) => {
    return value ? new Date(value) : null;
  };

  const toJson = (value) => {
    return value ? new Date(value).toISOString() : null;
  };

  return (
    <HorizontalContainer>
      <IcInputContainer>
        <IcInputLabel label={label} />
        <IcInputComponentContainer className="dateTimeContainer">
          <DateTimePicker
            className="dateTimeBox"
            disableClock
            format="dd/MM/y HH:mm:ss"
            value={dateTime}
            onChange={(value) => {
              setDateTime(value);
              onChange(toJson(value));
            }}
          />
        </IcInputComponentContainer>
      </IcInputContainer>
      <IcTooltip label={tooltip} placement="top" className="toolTip">
        <SlottedSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="24px" width="24px">
          <path d="M11 7V9H13V7H11M14 17V15H13V11H10V13H11V15H10V17H14M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12M20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12Z" />
        </SlottedSVG>
      </IcTooltip>
    </HorizontalContainer>
  );
};

DateTimeField.propTypes = {
  value: shape({
    start: string,
    end: string,
  }),
  label: string.isRequired,
  tooltip: string.isRequired,
  onChange: func.isRequired,
};

export default DateTimeField;

import "./IcDataRowHexValue.css";

import { IcDataRow } from "@ukic/react";
import { string } from "prop-types";

const IcDataRowHexValue = ({ label, value }) => {
  return (
    <IcDataRow label={label}>
      <div className="hexadecimal-text" slot="value">
        {value}
      </div>
    </IcDataRow>
  );
};

IcDataRowHexValue.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
};

export default IcDataRowHexValue;

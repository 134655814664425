import { arrayOf, bool, number, oneOfType, shape, string } from "prop-types";

const signatureAlgorithm = {
  name: string.isRequired,
  params: string,
};

export const extension = {
  name: string.isRequired,
  critical: bool.isRequired,
  data: string,
  ca: bool,
  pathLenConstraint: oneOfType([string, number]),
  key: string,
  certIssuer: string,
  certSerialNumber: string,
  accessDescriptions: arrayOf(
    shape({
      accessMethod: string.isRequired,
      accessLocation: string.isRequired,
    }),
  ),
  policies: arrayOf(
    shape({
      policyIdentifier: string.isRequired,
      policyQualifier: string,
    }),
  ),
  distributionPoints: arrayOf(
    shape({
      name: string,
      cRLIssuer: string,
      reasons: string,
    }),
  ),
  permittedSubtrees: arrayOf(
    shape({
      base: string.isRequired,
      minimum: number,
      maximum: number,
    }),
  ),
  excludedSubtrees: arrayOf(
    shape({
      base: string.isRequired,
      minimum: number,
      maximum: number,
    }),
  ),
  requireExplicitPolicy: string,
  inhibitPolicyMapping: string,
  mappings: arrayOf(
    shape({
      issuerDomainPolicy: string,
      subjectDomainPolicy: string,
    }),
  ),
  attributes: arrayOf(
    shape({
      id: string.isRequired,
      values: string.isRequired,
    }),
  ),
};

const publicKey = {
  modulus: string,
  publicExponent: string,
  data: string,
};

export const publicKeyCore = {
  id: string.isRequired,
  certificateIds: arrayOf(string).isRequired,
  algorithm: shape(signatureAlgorithm).isRequired,
  publicKey: shape(publicKey).isRequired,
};

export const publicKeySubject = {
  algorithm: shape(signatureAlgorithm).isRequired,
  publicKey: shape(publicKey).isRequired,
};

export const certificateCoreSummary = {
  id: string.isRequired,
  serialNumber: string,
  issuer: string,
  subject: string,
};

export const certificateCore = {
  id: string.isRequired,
  certificate: shape({
    version: number.isRequired,
    serialNumber: string.isRequired,
    signatureAlgorithm: shape(signatureAlgorithm).isRequired,
    issuer: string.isRequired,
    validity: shape({
      notBefore: string.isRequired,
      notAfter: string.isRequired,
    }).isRequired,
    subject: string.isRequired,
    subjectPublicKeyInfo: shape(publicKeySubject).isRequired,
    extensions: arrayOf(shape(extension)).isRequired,
  }).isRequired,
  certificateSignatureAlgorithm: shape(signatureAlgorithm).isRequired,
  certificateSignatureValue: string.isRequired,
  issuerCertificates: arrayOf(shape(certificateCoreSummary)),
  childCertificates: arrayOf(shape(certificateCoreSummary)),
  selfSigned: bool,
  activityLog: arrayOf(
    shape({
      timestamp: string.isRequired,
      user: string.isRequired,
      activityType: string.isRequired,
    }),
  ).isRequired,
};

import "./HorizontalContainer.css";

import { string } from "prop-types";
import { node } from "prop-types";

const HorizontalContainer = ({ slot, children }) => {
  return (
    <div className="horizontal-container" slot={slot}>
      {children}
    </div>
  );
};

HorizontalContainer.propTypes = {
  slot: string,
  children: node.isRequired,
};

export default HorizontalContainer;

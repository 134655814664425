import { IcAlert, IcBreadcrumb, IcBreadcrumbGroup, IcPageHeader } from "@ukic/react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import CertificateListView from "../../components/ListView/CertificateListView";
import PageNavigation from "../../components/ListView/PageNavigation";

const CertificateSavedPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const location = useLocation();
  const certificates = location.state?.certificates || [];
  const pageCount = Math.ceil(certificates.length / 100);
  const [currentCertificates, setCurrentCertificates] = useState([]);

  const setPage = (pageNumber = 0) => {
    setCurrentCertificates(certificates.slice(pageNumber * 100, (pageNumber + 1) * 100));
  };

  useEffect(() => {
    setPage();
  }, []);

  if (certificates.length === 0) {
    navigate("/certificates");
  }

  return (
    <div className="main-area" style={{ marginBottom: "24px" }}>
      <Header selected="certificates" />
      <IcPageHeader heading={`${certificates.length} Certificate${certificates.length !== 1 ? "s" : ""} Saved`}>
        <IcBreadcrumbGroup slot="breadcrumbs">
          <IcBreadcrumb pageTitle="Certificates" href="/certificates" />
          <IcBreadcrumb current pageTitle="Saved" href="/certificates/saved" />
        </IcBreadcrumbGroup>

        {error && (
          <IcAlert
            slot="subheading"
            variant="error"
            heading="Warning"
            message={error}
            dismissible
            onIcDismiss={() => setError(null)}
          />
        )}
      </IcPageHeader>
      <CertificateListView certificates={currentCertificates} />
      <PageNavigation pageCount={pageCount} onPageChange={setPage} />
    </div>
  );
};

export default CertificateSavedPage;

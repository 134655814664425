import "swagger-ui-react/swagger-ui.css";

import SwaggerUI from "swagger-ui-react";

import Header from "../../components/Header/Header";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import config from "../../utils/config";

const SwaggerUiPage = () => {
  const { token } = useAuthorisationToken();

  const requestInterceptor = (req) => {
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  };

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header />
      <SwaggerUI
        url={`${config.certificateStoreApi}/v3/api-docs`}
        showMutatedRequest={true}
        requestInterceptor={requestInterceptor}
      />
    </div>
  );
};

export default SwaggerUiPage;

import { IcDataRow } from "@ukic/react";
import { arrayOf, shape } from "prop-types";

import { certificateCoreSummary } from "../../types/index";
import Error from "../Error/Error";
import ListViewItem from "./ListViewItem";

const CertificateListView = ({ certificates }) => {
  if (certificates === null || certificates.length === 0) {
    return <Error heading="No certificates found" />;
  }

  return certificates.map((cert, idx) => (
    <ListViewItem
      headingPrefix={`ID: `}
      id_={`${cert.id ? cert.id : cert}`}
      url={`/certificates/${cert.id ? cert.id : cert}`}
      key={`certs${idx}`}
    >
      <IcDataRow
        label="Serial Number"
        size="small"
        value={cert.certificate ? cert.certificate.serialNumber : cert.serialNumber}
      />
      <IcDataRow label="Subject" size="small" value={cert.certificate ? cert.certificate.subject : cert.subject} />
      <IcDataRow label="Issuer" size="small" value={cert.certificate ? cert.certificate.issuer : cert.issuer} />
    </ListViewItem>
  ));
};

CertificateListView.propTypes = {
  certificates: arrayOf(shape(certificateCoreSummary)).isRequired,
};

export default CertificateListView;

import { IcDataRow } from "@ukic/react";
import { arrayOf, shape } from "prop-types";

import { publicKeyCore } from "../../types/index";
import Error from "../Error/Error";
import ListViewItem from "./ListViewItem";

const KeyListView = ({ keys }) => {
  if (keys === null || keys.length === 0) {
    return <Error heading="No keys found" />;
  }

  return keys.map((key, idx) => (
    <ListViewItem headingPrefix={`Key ID: `} id_={`${key.id}`} url={`/keys/${key.id}`} key={`key${idx}`}>
      <IcDataRow label="Key Type" size="small" value={key.algorithm.name} />
    </ListViewItem>
  ));
};

KeyListView.propTypes = {
  keys: arrayOf(shape(publicKeyCore)).isRequired,
};

export default KeyListView;

import "./CertificateForm.css";

import { IcButton, IcDataEntity, IcDataRow, IcLink, IcSectionContainer, IcTypography } from "@ukic/react";
import { shape } from "prop-types";
import { useNavigate } from "react-router-dom";

import { certificateCore } from "../../types";
import HorizontalContainer from "../HorizontalContainer/HorizontalContainer";
import ExtensionForm from "./ExtensionForm";
import IcDataRowHexValue from "./IcDataRowHexValue";
import { KeySubForm } from "./KeyForm";

const CertificateForm = ({ certificate }) => {
  const navigate = useNavigate();

  const certificateLink = (id) => {
    return (
      <>
        <IcLink href={"/certificates/" + id}>{id}</IcLink>
        <br />
      </>
    );
  };

  const displayCount = (count) => {
    if (!count) {
      return "";
    } else if (count.length >= 10) {
      return `(${count.length}+)`;
    } else {
      return `(${count.length})`;
    }
  };

  return (
    <>
      <IcSectionContainer>
        <IcDataEntity heading="Summary">
          <IcDataRow label="ID" value={certificate.id} />
          <IcDataRow label="Version" value={certificate.certificate.version} />
          <IcDataRowHexValue label="Serial Number" value={certificate.certificate.serialNumber} />
          <IcDataRow label="Issuer" value={certificate.certificate.issuer} />
          <IcDataRow label="Not Before" value={certificate.certificate.validity.notBefore} />
          <IcDataRow label="Not After" value={certificate.certificate.validity.notAfter} />
          <IcDataRow label="Subject" value={certificate.certificate.subject} />
          {certificate.certificate.issuerId && <IcDataRow label="Issuer Id" value={certificate.certificate.issuerId} />}
          {certificate.certificate.subjectId && (
            <IcDataRow label="Subject Id" value={certificate.certificate.subjectId} />
          )}
        </IcDataEntity>
      </IcSectionContainer>
      <IcSectionContainer>
        <IcDataEntity heading="Subject Public Key Info">
          <IcDataRow label="Key Id">
            <IcLink variant="body" slot="value" href={"/keys/" + certificate.certificate.subjectPublicKeyInfo.id}>
              {certificate.certificate.subjectPublicKeyInfo.id}
            </IcLink>
          </IcDataRow>
          <KeySubForm pubKey={certificate.certificate.subjectPublicKeyInfo} />
        </IcDataEntity>
      </IcSectionContainer>
      <IcSectionContainer>
        <IcDataEntity heading="Extensions">
          {certificate.certificate.extensions.map((extension, idx) => {
            return (
              <div key={`extension${idx}`}>
                <ExtensionForm extension={extension} />
              </div>
            );
          })}
        </IcDataEntity>
      </IcSectionContainer>
      <IcSectionContainer>
        <IcDataEntity heading="Signature">
          <IcDataRow label="Algorithm" value={certificate.certificateSignatureAlgorithm.name} />
          <IcDataRowHexValue label="Value" value={certificate.certificateSignatureValue} />
          <IcDataRow label={`Issuers ${displayCount(certificate.issuerCertificates)}`}>
            <HorizontalContainer slot="value">
              <IcTypography variant="body" slot="value">
                {certificate.selfSigned
                  ? "Self Signed"
                  : !!certificate.issuerCertificates && certificate.issuerCertificates.length > 0
                    ? certificate.issuerCertificates.map((issuer, idx) => {
                        return <div key={`issuer${idx}`}>{certificateLink(issuer.id)}</div>;
                      })
                    : "None"}
              </IcTypography>
              {!!certificate.issuerCertificates && (
                <IcButton
                  variant="primary"
                  onClick={() => navigate(`/certificates/${certificate.id}/issuers`)}
                  className="button"
                  slot="value"
                >
                  More Info
                </IcButton>
              )}
            </HorizontalContainer>
          </IcDataRow>
          <IcDataRow label={`Children ${displayCount(certificate.childCertificates)}`}>
            <HorizontalContainer slot="value">
              <IcTypography variant="body" slot="value">
                {!!certificate.childCertificates && certificate.childCertificates.length > 0
                  ? certificate.childCertificates.map((childCertificate, idx) => {
                      return <div key={`children${idx}`}>{certificateLink(childCertificate.id)}</div>;
                    })
                  : "None"}
              </IcTypography>
              {!!certificate.childCertificates && (
                <IcButton
                  variant="primary"
                  onClick={() => navigate(`/certificates/${certificate.id}/children`)}
                  className="button"
                >
                  More Info
                </IcButton>
              )}
            </HorizontalContainer>
          </IcDataRow>
        </IcDataEntity>
      </IcSectionContainer>
      <IcSectionContainer>
        <IcDataEntity heading="Metadata">
          <IcDataRow label="Activity Log">
            <IcTypography variant="body" slot="value">
              <table>
                <tbody>
                  {certificate.activityLog.map((metadata, idx) => {
                    return (
                      <tr key={`metadata${idx}`}>
                        <td key={`metadata${idx} activityType`}>{metadata.activityType}</td>
                        <td key={`metadata${idx} timestamp`}>{metadata.timestamp}</td>
                        <td key={`metadata${idx} user`}>{metadata.user}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </IcTypography>
          </IcDataRow>
        </IcDataEntity>
      </IcSectionContainer>
    </>
  );
};

CertificateForm.propTypes = {
  certificate: shape(certificateCore).isRequired,
};

export default CertificateForm;

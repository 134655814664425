import "./IcDataRowHexValue.css";

import { IcDataRow, IcTypography } from "@ukic/react";
import _ from "lodash";
import { node, number, oneOfType, shape, string } from "prop-types";

import { extension } from "../../types";
import HorizontalContainer from "../HorizontalContainer/HorizontalContainer";

const ExtensionForm = ({ extension }) => {
  const Wrap = ({ label, value, hexValue }) => {
    return (
      <div>
        {label ? `${label}: ` : ""}
        {value}
        <span className="hexadecimal-text">{hexValue}</span>
      </div>
    );
  };

  Wrap.propTypes = {
    label: string,
    value: oneOfType([string, number]),
    hexValue: string,
  };

  const WrapMultiField = ({ label, children }) => {
    return (
      <HorizontalContainer>
        {label}
        <span>{children}</span>
      </HorizontalContainer>
    );
  };

  WrapMultiField.propTypes = {
    label: string,
    children: node.isRequired,
  };

  return (
    <IcDataRow label={_.startCase(_.upperFirst(extension.name))}>
      <IcTypography variant="body" slot="value">
        <div>{extension.critical ? "Critical" : "Non-critical"}</div>
        {!!extension.data && <Wrap value={extension.data} />}
        {extension.ca !== undefined && (
          <Wrap value={extension.ca ? "Certificate Authority" : "Not Certificate Authority"} />
        )}
        {extension.pathLenConstraint !== undefined && (
          <Wrap label="Path Len Constraint" value={extension.pathLenConstraint} />
        )}
        {!!extension.key && <Wrap hexValue={extension.key} />}
        {!!extension.certIssuer && <Wrap label="Issuer" value={extension.certIssuer} />}
        {!!extension.certSerialNumber && <Wrap label="Serial Number" value={extension.certSerialNumber} />}
        {!!extension.accessDescriptions &&
          extension.accessDescriptions.map((ad, idx) => {
            return <Wrap label={ad.accessMethod} value={ad.accessLocation} key={`accessDesc${idx}`} />;
          })}
        {!!extension.policies &&
          extension.policies.map((policy, idx) => {
            return policy.policyQualifier ? (
              <Wrap label={policy.policyIdentifier} value={policy.policyQualifier} key={`policy${idx}`} />
            ) : (
              <Wrap value={policy.policyIdentifier} key={`policy${idx}`} />
            );
          })}
        {!!extension.distributionPoints &&
          extension.distributionPoints.map((dp, idx) => {
            return (
              <WrapMultiField label={`[${idx}]`} key={`distpoint${idx}`}>
                {dp.name && <Wrap label="Name" value={dp.name} />}
                {dp.cRLIssuer && <Wrap label="Issuer" value={dp.cRLIssuer} />}
                {dp.reasons && <Wrap label="Reasons" value={dp.reasons} />}
              </WrapMultiField>
            );
          })}
        {!!extension.permittedSubtrees && (
          <WrapMultiField label="Permitted">
            {extension.permittedSubtrees.map((subtree, idx) => {
              return (
                <WrapMultiField label={`[${idx}]`} key={`permitted${idx}`}>
                  <Wrap value={subtree.base} />
                  {subtree.minimum !== undefined && <Wrap label="Minimum" value={subtree.minimum} />}
                  {subtree.maximum !== undefined && <Wrap label="Maximum" value={subtree.maximum} />}
                </WrapMultiField>
              );
            })}
          </WrapMultiField>
        )}
        {!!extension.excludedSubtrees && (
          <WrapMultiField label="Excluded">
            {extension.excludedSubtrees.map((subtree, idx) => {
              return (
                <WrapMultiField label={`[${idx}]`} key={`excluded${idx}`}>
                  <Wrap value={subtree.base} />
                  {subtree.minimum !== undefined && <Wrap label="Minimum" value={subtree.minimum} />}
                  {subtree.maximum !== undefined && <Wrap label="Maximum" value={subtree.maximum} />}
                </WrapMultiField>
              );
            })}
          </WrapMultiField>
        )}
        {!!extension.requireExplicitPolicy && (
          <Wrap label="Require Explicit Policy" value={extension.requireExplicitPolicy} />
        )}
        {!!extension.inhibitPolicyMapping && (
          <Wrap label="Inhibit Policy Mapping" value={extension.inhibitPolicyMapping} />
        )}
        {!!extension.mappings &&
          extension.mappings.map((mapping, idx) => {
            return (
              <WrapMultiField label={`[${idx}]`} key={`mapping${idx}`}>
                {mapping.issuerDomainPolicy && <Wrap label="Issuer Domain Policy" value={mapping.issuerDomainPolicy} />}
                {mapping.subjectDomainPolicy && (
                  <Wrap label="Subject Domain Policy" value={mapping.subjectDomainPolicy} />
                )}
              </WrapMultiField>
            );
          })}
        {!!extension.attributes &&
          extension.attributes.map((attribute, idx) => {
            return <Wrap label={attribute.id} value={attribute.values} key={`attribute${idx}`} />;
          })}
        {!!extension.date && <Wrap value={extension.date} />}
        {!!extension.notBefore && <Wrap label="Not Before" value={extension.notBefore} />}
        {!!extension.notAfter && <Wrap label="Not After" value={extension.notAfter} />}
        {!!extension.distributionPoint && <Wrap label="Distribution Point" value={extension.distributionPoint} />}
        {!!extension.onlySomeReasons && <Wrap label="Only Some Reasons" value={extension.onlySomeReasons} />}
        {!!extension.onlyContains && <Wrap label="Only Contains" value={extension.onlyContains} />}
        {!!extension.timestampList &&
          extension.timestampList.map((sct, idx) => {
            return (
              <WrapMultiField label={`[${idx}]`} key={`sct${idx}`}>
                <Wrap label="Version" value={sct.version} />
                <Wrap label="Log ID" hexValue={sct.logId} />
                <Wrap label="Timestamp" value={sct.timestamp} />
                {sct.extensions.length !== 0 ? (
                  <Wrap label="Extensions" hexValue={sct.extension} />
                ) : (
                  <Wrap label="Extensions" value="None" />
                )}
                <Wrap label="Signature Algorithm" value={sct.signatureAlgorithm} />
                <Wrap label="Signature" hexValue={sct.signature} />
              </WrapMultiField>
            );
          })}
      </IcTypography>
    </IcDataRow>
  );
};

ExtensionForm.propTypes = {
  extension: shape(extension).isRequired,
};

export default ExtensionForm;

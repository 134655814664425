import { IcStatusTag } from "@ukic/react";
import { string } from "prop-types";

const toStatus = (status) => {
  switch (status) {
    case "SUCCEEDED":
      return <IcStatusTag label="Success" status="success" />;
    case "RUNNING":
      return <IcStatusTag label="In Progress" status="warning" />;
    case "FAILED":
      return <IcStatusTag label="Failed" status="danger" />;
    case "TIMED_OUT":
      return <IcStatusTag label="Timed Out" status="danger" />;
    default:
      return <IcStatusTag label={status} status="neutral" />;
  }
};

const IngestStatus = ({ status, slot }) => {
  return (
    <div slot={slot} style={{ margin: "-4px 0px" }}>
      {toStatus(status)}
    </div>
  );
};

IngestStatus.propTypes = {
  slot: string,
  status: string.isRequired,
};

export default IngestStatus;

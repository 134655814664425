import "./Sidebar.css";

import { element } from "prop-types";

const Sidebar = ({ children }) => {
  return <div className="scrollable">{children}</div>;
};

Sidebar.propTypes = {
  children: element.isRequired,
};

export default Sidebar;

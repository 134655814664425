const config = {
  version: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENVIRONMENT,
  classification: "official",
  certificateStoreApi: process.env.REACT_APP_CERTIFICATE_STORE_API_URL,
  auth: {
    clientHost: process.env.REACT_APP_AUTH_CLIENT_HOST,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  },
};

export default config;

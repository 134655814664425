import "./CertificateDetailsPage.css";

import {
  IcAlert,
  IcBreadcrumb,
  IcBreadcrumbGroup,
  IcButton,
  IcDataEntity,
  IcDataRow,
  IcPageHeader,
  IcSectionContainer,
  IcSelect,
  IcTab,
  IcTabContext,
  IcTabGroup,
  IcTabPanel,
  IcToast,
  IcToastRegion,
} from "@ukic/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Error from "../../components/Error/Error";
import CertificateForm from "../../components/Form/CertificateForm";
import Header from "../../components/Header/Header";
import HorizontalContainer from "../../components/HorizontalContainer/HorizontalContainer";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import {
  getCertificateById,
  getEncodedCertificateById,
  reparseCertificate,
  revalidateCertificate,
} from "../../utils/axiosRequests";
import { NotFoundError } from "../../utils/errors";

const CertificateDetailsPage = () => {
  const { id } = useParams();
  const { token } = useAuthorisationToken();
  const [certificate, setCertificate] = useState(null);
  const [encodedCertificate, setEncodedCertificate] = useState(null);
  const [encodingOptions, setEncodingOptions] = useState("DEFAULT");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [encodedCertificateFormat, setEncodedCertificateFormat] = useState("BASE_64");

  const toastRegion = useRef(null);
  const successToast = useRef(null);
  const failToast = useRef(null);

  const getCertificate = async () => {
    setLoading(true);
    const payload = {
      includeParentCertificate: "SUMMARY",
      includeChildCertificates: "SUMMARY",
      encodingOptions: encodingOptions,
    };

    await getCertificateById(id, payload, token)
      .then((data) => setCertificate(data))
      .catch((e) => {
        if (!(e instanceof NotFoundError)) {
          setError(`${e.name}. ${e.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getEncodedCertificate = async () => {
    await getEncodedCertificateById(id, encodedCertificateFormat, token)
      .then((data) => setEncodedCertificate(data))
      .catch((e) => {
        if (!(e instanceof NotFoundError)) {
          setError(`${e.name}. ${e.message}`);
        }
      });
  };

  const validate = async () => {
    const payload = {
      includeParentCertificate: "SUMMARY",
      includeChildCertificates: "SUMMARY",
    };

    await reparseCertificate(id, payload, token);

    await revalidateCertificate(id, payload, token)
      .then((data) => {
        setCertificate(data);
        toastRegion.current.openToast = successToast.current;
      })
      .catch((e) => {
        console.error(e.message);
        toastRegion.current.openToast = failToast.current;
      });
  };

  const download = async (filename) => {
    const blob = new Blob([encodedCertificate], { type: "text/plain" });
    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(blob);
    anchor.download = filename;
    anchor.click();
    anchor.remove();
    URL.revokeObjectURL(anchor.href);
  };

  useEffect(() => {
    getCertificate();
    getEncodedCertificate();
  }, [id, encodingOptions]);

  useEffect(() => {
    getEncodedCertificate();
  }, [encodedCertificateFormat]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header selected="certificates" />
      {error && <IcAlert heading="Warning" message={error} variant="error" style={{ margin: "16px 24px" }} />}
      {certificate ? (
        <>
          <IcPageHeader heading={`Certificate Details`} reverseOrder>
            <IcButton variant="primary" onClick={() => validate()} className="button" slot="actions">
              Validate
            </IcButton>
            <IcBreadcrumbGroup slot="breadcrumbs">
              <IcBreadcrumb pageTitle="Certificates" href="/certificates" />
              <IcBreadcrumb pageTitle="Query" href="/certificates/query" />
              <IcBreadcrumb current pageTitle="Details" href={"/certificates/" + id} />
            </IcBreadcrumbGroup>
          </IcPageHeader>
          <IcTabContext>
            <IcTabGroup label="Tab Group">
              <IcTab>Information</IcTab>
              <IcTab>Encoded</IcTab>
            </IcTabGroup>
            <IcTabPanel>
              <IcSectionContainer>
                <IcSelect
                  placeholder="Select an option..."
                  label="Hex Encoding"
                  options={[
                    { label: "Decimal", value: "DECIMAL" },
                    { label: "Base64", value: "BASE_64" },
                    { label: "Lowercase", value: "LOWER_CASE" },
                  ]}
                  value={encodingOptions}
                  showClearButton={encodingOptions != "DEFAULT"}
                  onIcChange={(event) => {
                    setEncodingOptions(event.detail.value);
                  }}
                  onIcClear={() => setEncodingOptions("DEFAULT")}
                />
              </IcSectionContainer>
              <CertificateForm certificate={certificate} />
            </IcTabPanel>
            <IcTabPanel>
              <IcSectionContainer>
                <HorizontalContainer>
                  <IcButton
                    variant="primary"
                    onClick={() =>
                      setEncodedCertificateFormat(encodedCertificateFormat === "BASE_64" ? "DER" : "BASE_64")
                    }
                    className="button"
                  >
                    {`Switch to ${encodedCertificateFormat === "BASE_64" ? "DER" : "BASE 64"} encoding`}
                  </IcButton>
                  <IcButton
                    variant="secondary"
                    onClick={() =>
                      encodedCertificateFormat === "BASE_64" ? download(`${id}.pem`) : download(`${id}.txt`)
                    }
                    className="button"
                  >
                    Download
                  </IcButton>
                </HorizontalContainer>
                <IcDataEntity>
                  <IcDataRow>
                    <div className="monospace" slot="value">
                      {encodedCertificate}
                    </div>
                  </IcDataRow>
                </IcDataEntity>
              </IcSectionContainer>
            </IcTabPanel>
          </IcTabContext>
          <IcToastRegion ref={toastRegion}>
            <IcToast
              heading="Validation successful"
              variant="success"
              dismissMode="automatic"
              autoDismissTimeout={5000}
              ref={successToast}
            />
            <IcToast
              heading="Validation failed"
              variant="error"
              dismissMode="automatic"
              autoDismissTimeout={5000}
              ref={failToast}
            />
          </IcToastRegion>
        </>
      ) : (
        <Error heading="Certificate not found" subheading={`The Certificate with ID: ${id} does not exist.`} />
      )}
    </div>
  );
};

export default CertificateDetailsPage;

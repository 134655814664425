import { node } from "prop-types";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";

import Loading from "../components/Loading/Loading";
import { fetchOAuthToken, refreshOAuthToken } from "../utils/axiosRequests";
import config from "../utils/config";

const AuthorisationContext = createContext();

const AuthorisationProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const [token, setToken_] = useState(null);
  const [refreshToken, setRefreshToken] = useState(cookies.refreshToken);
  const [isLoading, setIsLoading] = useState(true);

  const fetchToken = async (code) => {
    await fetchOAuthToken(code)
      .then((data) => {
        // Set new token
        setToken_(data.access_token);
        setRefreshToken(data.refresh_token);
        setCookie("refreshToken", data.refresh_token);
      })
      .catch((e) => console.log(e));
  };

  const fetchRefreshToken = async (refreshToken) => {
    await refreshOAuthToken(refreshToken)
      .then((data) => {
        // Set new token
        setToken_(data.access_token);
      })
      .catch(() => {
        // Refresh token has expired
        removeToken();
      });
  };

  const removeToken = () => {
    removeCookie("refreshToken");
    setToken_(null);
    setIsLoading(true);
    setRefreshToken(null);
  };

  useEffect(() => {
    if (code && !token) {
      // Redirect from auth provider
      fetchToken(code);
    } else if (refreshToken && !token) {
      // Use refresh token to get new token
      fetchRefreshToken(refreshToken);
    } else if (!refreshToken && !token) {
      // Fetch new token from auth provider
      if (config.auth.clientHost && config.auth.clientId) {
        window.location.href = `${config.auth.clientHost}/login?client_id=${config.auth.clientId}&redirect_uri=${window.location.protocol}//${window.location.host}/login&response_type=code`;
      } else {
        // REPORT ERROR
      }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      setIsLoading(false);
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      token,
      removeToken,
    }),
    [token],
  );

  if (isLoading) {
    return <Loading />;
  }

  return <AuthorisationContext.Provider value={contextValue}>{children}</AuthorisationContext.Provider>;
};

AuthorisationProvider.propTypes = {
  children: node,
};

const useAuthorisationToken = () => useContext(AuthorisationContext);

export { AuthorisationContext, AuthorisationProvider, useAuthorisationToken };

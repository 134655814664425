import "./Loading.css";

import { IcLoadingIndicator } from "@ukic/react";
import { bool } from "prop-types";

const Loading = ({ inline }) => {
  return <IcLoadingIndicator class={inline ? "loading-inline" : "loading"} type="circular" label="Loading..." />;
};

Loading.propTypes = {
  inline: bool,
};

export default Loading;

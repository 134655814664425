import { IcButton, IcHero, IcPageHeader, IcTextField } from "@ukic/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";

const KeyHomePage = () => {
  const [keyId, setKeyId] = useState(null);
  const navigate = useNavigate();

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header selected="keys" />
      <IcHero
        heading="Query by Keys"
        subheading="Use a variety of filters to search the database for the Keys that you're looking for."
      >
        <IcButton variant="primary" slot="interaction" onClick={() => navigate("/keys/query")}>
          Go there
        </IcButton>
      </IcHero>
      <IcPageHeader heading="Fetch Key by ID" data-testid="fetchByIdContainer">
        <IcTextField
          slot="input"
          placeholder="Enter a UUID"
          label="Input"
          hideLabel
          onIcChange={(event) => setKeyId(event.detail.value)}
        />
        <IcButton slot="actions" variant="primary" onClick={() => (keyId ? navigate(`/keys/${keyId}`) : null)}>
          Search
        </IcButton>
      </IcPageHeader>
    </div>
  );
};

export default KeyHomePage;

import "./IngestDetailsPage.css";

import { IcAlert, IcButton, IcDataEntity, IcDataRow, IcSectionContainer, IcTooltip, SlottedSVG } from "@ukic/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Error from "../../components/Error/Error";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { queryIngestPipelineById } from "../../utils/axiosRequests";
import { NotFoundError } from "../../utils/errors";
import IngestStatus from "./IngestStatus";

const IngestDetailsPage = () => {
  const { token } = useAuthorisationToken();
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [execution, setExecution] = useState(null);
  const [loading, setLoading] = useState(true);
  const baseURL = "https://eu-west-2.console.aws.amazon.com/states/home?region=eu-west-2#/v2/executions/details/";

  const getStatus = async () => {
    await queryIngestPipelineById(id, token)
      .then((data) => setExecution(data))
      .catch((e) => {
        if (!(e instanceof NotFoundError)) {
          setError(`${e.name}. ${e.message}`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStatus();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="ingest-details-page">
      <Header selected="ingest" />
      {error && <IcAlert heading="Warning" message={error} variant="error" style={{ margin: "16px 24px" }} />}
      {execution ? (
        <>
          <IcSectionContainer>
            <IcDataEntity heading="Summary">
              <IcDataRow label="ID">
                <div slot="value">
                  {execution.id}
                  <IcTooltip label="View in AWS Console" placement="bottom">
                    <IcButton
                      variant="icon"
                      onClick={() => window.open(baseURL + execution.arn, "_blank")}
                      className="ingest-details-button-to-aws"
                      aria-label=""
                    >
                      <SlottedSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
                      </SlottedSVG>
                    </IcButton>
                  </IcTooltip>
                </div>
              </IcDataRow>
              <IcDataRow label="File Name" value={execution.filename} />
              <IcDataRow label="Uploaded By" value={execution.uploadedBy} />
              <IcDataRow label="Status">
                <IngestStatus status={execution.status} slot="value" />
              </IcDataRow>
              <IcDataRow label="Started" value={execution.started} />
              {execution.ended && <IcDataRow label="Finshed" value={execution.ended} />}
            </IcDataEntity>
          </IcSectionContainer>
          {execution.status !== "RUNNING" && (
            <IcSectionContainer>
              <IcDataEntity heading="Output">
                {(execution.passCount || execution.failedCount) && (
                  <>
                    <IcDataRow label="Certificates added to database" value={execution.passCount || 0} />
                    <IcDataRow label="Certificates failed validation" value={execution.failCount || 0} />
                  </>
                )}
                {execution.error && <IcDataRow label="Error" value={execution.error} />}
                {execution.cause && <IcDataRow label="Cause" value={execution.cause} />}
              </IcDataEntity>
            </IcSectionContainer>
          )}
        </>
      ) : (
        <Error
          heading="Execution not found"
          subheading={`The Ingest Pipeline Execution with ID: ${id} does not exist.`}
        />
      )}
    </div>
  );
};

export default IngestDetailsPage;

import "./App.css";

import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import Error from "../../components/Error/Error";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { AuthorisationProvider, useAuthorisationToken } from "../../context/AuthorisationProvider";
import CertificateDetailsPage from "../Certificates/CertificateDetailsPage";
import CertificateHomePage from "../Certificates/CertificateHomePage";
import CertificateQueryPage from "../Certificates/CertificateQueryPage";
import CertificateRelationshipPage from "../Certificates/CertificateRelationshipPage";
import CertificateSavedPage from "../Certificates/CertificateSavedPage";
import HomePage from "../Home/HomePage";
import IngestDetailsPage from "../Ingest/IngestDetailsPage";
import IngestPage from "../Ingest/IngestPage";
import KeyCertificatesPage from "../Keys/KeyCertificatesPage";
import KeyDetailsPage from "../Keys/KeyDetailsPage";
import KeyHomePage from "../Keys/KeyHomePage";
import KeyQueryPage from "../Keys/KeyQueryPage";
import SwaggerUiPage from "../Swagger/SwaggerUiPage";

const Login = () => {
  const nav = useNavigate();
  useEffect(() => nav("/"));
};

const AppRouter = () => {
  const { token } = useAuthorisationToken();

  const renderErrorPage = () => {
    return (
      <>
        <Header />
        <Error heading="Page was not found" message="Sorry, we couldn't find that page." />
      </>
    );
  };

  if (!token) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/certificates" element={<CertificateHomePage />} />
        <Route path="/certificates/query" element={<CertificateQueryPage />} />
        <Route path="/certificates/:id" element={<CertificateDetailsPage />} />
        <Route path="/certificates/:id/issuers" element={<CertificateRelationshipPage />} />
        <Route path="/certificates/:id/children" element={<CertificateRelationshipPage getChild />} />
        <Route path="/certificates/saved" element={<CertificateSavedPage />} />
        <Route path="/keys" element={<KeyHomePage />} />
        <Route path="/keys/query" element={<KeyQueryPage />} />
        <Route path="/keys/:id" element={<KeyDetailsPage />} />
        <Route path="/keys/:id/certificates" element={<KeyCertificatesPage />} />
        <Route path="/ingest" element={<IngestPage />} />
        <Route path="/ingest/:id" element={<IngestDetailsPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/api" element={<SwaggerUiPage />} />
        <Route path="*" element={renderErrorPage()} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <AuthorisationProvider>
      <AppRouter />
    </AuthorisationProvider>
  );
};

export default App;

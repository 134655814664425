import { IcAlert, IcButton, IcChip, IcHero, IcPageHeader, IcTextField } from "@ukic/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { getCertificateByKey, saveCertificate, saveCertificateBlock } from "../../utils/axiosRequests";

const CertificateHomePage = () => {
  const beginCertificate = new RegExp("-----BEGIN CERTIFICATE-----\\n", "g");
  const navigate = useNavigate();
  const { token } = useAuthorisationToken();
  const [certificateId, setCertificateId] = useState(null);
  const [certificatePublicKey, setCertificatePublicKey] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [saveError, setSaveError] = useState(null);
  const [certificateCount, setCertificateCount] = useState(0);
  const [certificateOrChain, setCertificateOrChain] = useState(null);
  const [saveCertificateIsPEMFormat, setSaveCertificateIsPEMFormat] = useState(true);
  const [loading, setLoading] = useState(false);

  const queryCertificateIdByPublicKey = async () => {
    setLoading(true);
    await getCertificateByKey(certificatePublicKey, token)
      .then((data) => navigate(`/certificates/${data.id}`))
      .catch((e) => setFetchError(`${e.name}. ${e.message}`))
      .finally(setLoading(false));
  };

  // Save a single certificate and redirect to its details page
  const saveSingleCertificate = async () => {
    setLoading(true);
    await saveCertificate(certificateOrChain, token)
      .then((data) => navigate(`/certificates/${data.id}`))
      .catch((e) => setSaveError(`${e.name}. ${e.message}`))
      .finally(setLoading(false));
  };

  // Save multiple certificates and redirect to certificate list page
  const saveMultipleCertificates = async () => {
    setLoading(true);
    await saveCertificateBlock(certificateOrChain, token)
      .then((data) => navigate("saved", { state: { certificates: data.created } }))
      .catch((e) => setSaveError(`${e.name}. ${e.message}`))
      .finally(setLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ marginBottom: "24px" }}>
      <Header selected="certificates" />
      <IcHero
        heading="Query by Certificates"
        subheading="Use a variety of filters to search the database for the Certificates that you're looking for."
      >
        <IcButton variant="primary" slot="interaction" onClick={() => navigate("/certificates/query")}>
          Go there
        </IcButton>
      </IcHero>
      <IcPageHeader heading="Fetch Certificate by ID" data-testid="fetchByIdContainer">
        <IcTextField
          slot="input"
          placeholder="Enter a UUID"
          label="Input"
          hideLabel
          onIcChange={(event) => setCertificateId(event.detail.value)}
        />
        <IcButton
          slot="actions"
          variant="primary"
          onClick={() => (certificateId ? navigate("/certificates/" + certificateId) : null)}
        >
          Search
        </IcButton>
      </IcPageHeader>
      <IcPageHeader heading="Fetch Certificate by Public Key" data-testid="fetchByPubKeyContainer">
        {fetchError && <IcAlert slot="subheading" variant="error" heading="Warning" message={fetchError} dismissible />}
        <IcTextField
          rows={10}
          fullWidth
          slot="stepper"
          placeholder="Block that beings with '-----BEGIN PUBLIC KEY-----'."
          label="Input"
          hideLabel
          onIcChange={(event) => setCertificatePublicKey(event.detail.value)}
        />
        <IcButton slot="actions" variant="primary" onClick={() => queryCertificateIdByPublicKey()}>
          Search
        </IcButton>
      </IcPageHeader>
      <IcPageHeader heading="Save Certificate / Certificate Chain" data-testid="saveCertificateSection">
        {saveCertificateIsPEMFormat && certificateCount !== 0 && (
          <IcChip label={`Save ${certificateCount} certificate(s)`} slot="heading-adornment" />
        )}
        {saveError && <IcAlert slot="subheading" variant="error" heading="Warning" message={saveError} dismissible />}
        {saveCertificateIsPEMFormat ? (
          <IcTextField
            rows={10}
            fullWidth
            slot="stepper"
            placeholder="Block that beings with '-----BEGIN CERTIFICATE-----'."
            label="Input"
            hideLabel
            onIcChange={(event) => {
              setCertificateOrChain(event.detail.value);
              setCertificateCount((event.detail.value.match(beginCertificate) || []).length);
            }}
          />
        ) : (
          <IcTextField
            rows={10}
            fullWidth
            slot="stepper"
            placeholder="A single DER-encoded hexadecimal block."
            label="Input"
            hideLabel
            onIcChange={(event) => {
              setCertificateOrChain(event.detail.value);
              setCertificateCount((event.detail.value.match(beginCertificate) || []).length);
            }}
          />
        )}
        <IcButton
          slot="actions"
          variant="primary"
          onClick={() => setSaveCertificateIsPEMFormat(!saveCertificateIsPEMFormat)}
        >
          {saveCertificateIsPEMFormat ? "Switch to DER format" : "Switch to PEM format"}
        </IcButton>
        <IcButton
          slot="actions"
          variant="primary"
          onClick={() =>
            saveCertificateIsPEMFormat && certificateCount > 1 ? saveMultipleCertificates() : saveSingleCertificate()
          }
        >
          Save
        </IcButton>
      </IcPageHeader>
    </div>
  );
};

export default CertificateHomePage;

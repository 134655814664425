import { IcAccordion, IcAccordionGroup, IcSelect, IcTextField } from "@ukic/react";
import { func } from "prop-types";
import { useEffect, useState } from "react";

import DateTimeField from "../DateTimeField/DateTimeField";
import PublicKeyFilters from "../Filter/PublicKeyFilters";
import ExtensionFilters from "./ExtensionFilters";

const CertificateFilters = ({ setPayload }) => {
  const [filters, setFilters] = useState({});
  const fromTooltip = "Queries for certificates where the validity period is AFTER or overlaps with the given date.";
  const toTooltip = "Queries for certificates where the validity period is BEFORE or overlaps with the given date.";
  const debounce = 500;

  useEffect(() => {
    setPayload(filters);
  }, [filters]);

  const setFiltersField = (field, value) => {
    if (value !== undefined) {
      setFilters((prevFormData) => ({
        ...prevFormData,
        [field]: value !== "" ? value : null,
      }));
    }
  };

  return (
    <IcAccordionGroup groupTitle="Filters">
      <IcAccordion heading="Details" expanded>
        <IcTextField
          label="Serial Number"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("serialNumber", event.detail.value)}
          size="small"
        />
        <IcTextField
          label="Subject Name"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("subjectName", event.detail.value)}
          size="small"
        />
        <IcTextField
          label="Issuer Name"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("issuerName", event.detail.value)}
          size="small"
        />
      </IcAccordion>
      <IcAccordion heading="Valid Range">
        <DateTimeField label="From" tooltip={fromTooltip} onChange={(event) => setFiltersField("validFrom", event)} />
        <DateTimeField label="To" tooltip={toTooltip} onChange={(event) => setFiltersField("validTo", event)} />
      </IcAccordion>
      <IcAccordion heading="Public Key">
        <PublicKeyFilters debounce={debounce} setPayload={(value) => setFiltersField("publicKey", value)} />
        <IcTextField
          label="Public Key ID"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("publicKeyId", event.detail.value)}
          size="small"
        />
      </IcAccordion>
      <IcAccordion heading="Extensions">
        <ExtensionFilters setFiltersField={setFiltersField} />
      </IcAccordion>
      <IcAccordion heading="Signature">
        <IcTextField
          label="Signature Algorithm ID"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("signatureAlgorithmId", event.detail.value)}
          size="small"
        />
        <IcSelect
          label="Invalid Signature"
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          showClearButton
          onIcChange={(event) => setFiltersField("invalidatedSignature", event.detail.value)}
        />
        <IcSelect
          label="Self Signed"
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          showClearButton
          onIcChange={(event) => setFiltersField("selfSigned", event.detail.value)}
        />
        <IcTextField
          label="Issuer of (id)"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("issuerOfId", event.detail.value)}
          size="small"
        />
        <IcTextField
          label="Child of (id)"
          debounce={debounce}
          onIcChange={(event) => setFiltersField("childOfId", event.detail.value)}
          size="small"
        />
      </IcAccordion>
    </IcAccordionGroup>
  );
};

CertificateFilters.propTypes = {
  setPayload: func.isRequired,
};

export default CertificateFilters;

import axios from "axios";

import config from "./config";
import { CancelledError, InternalServerError, NetworkError, NotFoundError, UnprocessableEntity } from "./errors";

const abortControllers = {};

const axiosClient = (token, headers) => {
  const axiosInstance = axios.create({
    baseURL: config.certificateStoreApi,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      ...headers,
    },
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.message === "canceled") {
        throw new CancelledError();
      }

      // Network Error
      if (error.message === "Network Error") {
        throw new NetworkError("Did not recieve a response from the server");
      }

      // API Error
      if (error.response !== null) {
        if (error.response.data.error === "Not Found") {
          throw new NotFoundError(error.response.data.detail);
        } else if (error.response.data.error === "Internal Server Error") {
          throw new InternalServerError("An unknown error has occured");
        } else if (error.response.data.error === "Unprocessable Entity") {
          throw new UnprocessableEntity(error.response.data.detail);
        }

        throw new Error(error.response.data.error);
      }

      // Generic Error
      throw new Error("An unknown error has occured");
    },
  );

  return axiosInstance;
};

const revalidateCertificate = async (id, payload, token) => {
  return await axiosClient(token)
    .post(`/certificates/${id}/revalidate`, payload)
    .then((response) => response.data);
};

const reparseCertificate = async (id, payload, token) => {
  return await axiosClient(token)
    .post(`/certificates/${id}/reparse`, payload)
    .then((response) => response.data);
};

const getCertificateById = async (id, payload, token) => {
  return await axiosClient(token)
    .post(`/certificates/${id}`, payload)
    .then((response) => response.data);
};

const getCertificateByKey = async (payload, token) => {
  return await axiosClient(token, { "Content-Type": "text/plain" })
    .post(`/certificates/by-public-key`, payload)
    .then((response) => response.data);
};

const getEncodedCertificateById = async (id, encodedCertificateFormat, token) => {
  return await axiosClient(token)
    .get(`/certificates/${id}/encoded?format=${encodedCertificateFormat}`)
    .then((response) => response.data);
};

const saveCertificate = async (payload, token) => {
  return await axiosClient(token, { "Content-Type": "text/plain" })
    .post(`/certificates`, payload)
    .then((response) => response.data);
};

const saveCertificateBlock = async (payload, token) => {
  return await axiosClient(token, { "Content-Type": "text/plain" })
    .post(`/certificates/block`, payload)
    .then((response) => response.data);
};

const queryCertificates = async (payload, token) => {
  const url = "/certificates/query";
  // Abort any existing requests to this url
  if (abortControllers[url]) {
    abortControllers[url].abort();
  }

  // Save the abort signal
  const controller = new AbortController();
  abortControllers[url] = controller;

  // Make request
  return await axiosClient(token)
    .post(url, payload, { signal: controller.signal })
    .then((response) => {
      // Delete abort signal
      delete abortControllers[url];
      return response;
    });
};

const countCertificates = async (payload, token) => {
  const url = "/certificates/count";
  // Abort any existing requests to this url
  if (abortControllers[url]) {
    abortControllers[url].abort();
  }

  // Save the abort signal
  const controller = new AbortController();
  abortControllers[url] = controller;

  // Make request
  return await axiosClient(token)
    .post(url, payload, { signal: controller.signal })
    .then((response) => {
      // Delete abort signal
      delete abortControllers[url];
      return response;
    });
};

const getKeyById = async (id, token) => {
  return await axiosClient(token)
    .get(`/keys/${id}`)
    .then((response) => response.data);
};

const queryKeys = async (payload, token) => {
  const url = "/keys/query";
  // Abort any existing requests to this url
  if (abortControllers[url]) {
    abortControllers[url].abort();
  }

  // Save the abort signal
  const controller = new AbortController();
  abortControllers[url] = controller;

  // Make request
  return await axiosClient(token)
    .post(url, payload, { signal: controller.signal })
    .then((response) => {
      // Delete abort signal
      delete abortControllers[url];
      return response;
    });
};

const countKeys = async (payload, token) => {
  const url = "/keys/count";
  // Abort any existing requests to this url
  if (abortControllers[url]) {
    abortControllers[url].abort();
  }

  // Save the abort signal
  const controller = new AbortController();
  abortControllers[url] = controller;

  // Make request
  return await axiosClient(token)
    .post(url, payload, { signal: controller.signal })
    .then((response) => {
      // Delete abort signal
      delete abortControllers[url];
      return response;
    });
};

const getEncodedKeyById = async (id, encodedKeyFormat, token) => {
  return await axiosClient(token)
    .get(`/keys/${id}/encoded?format=${encodedKeyFormat}`)
    .then((response) => response.data);
};

const queryIngestPipeline = async (token) => {
  return await axiosClient(token)
    .get("/ingest/statuses")
    .then((response) => response.data);
};

const queryIngestPipelineById = async (id, token) => {
  return await axiosClient(token)
    .get(`/ingest/status/${id}`)
    .then((response) => response.data);
};

const appInfo = async (token) => {
  return await axiosClient(token)
    .get("/actuator/info")
    .then((response) => response.data);
};

const fetchOAuthToken = async (code) => {
  var payload = {
    grant_type: "authorization_code",
    code,
    redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    client_id: config.auth.clientId,
    client_secret: "",
  };

  return await axios
    .create({
      baseURL: config.auth.clientHost,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .post("/oauth2/token", payload)
    .then((response) => response.data);
};

const refreshOAuthToken = async (refresh_token) => {
  var payload = {
    grant_type: "refresh_token",
    refresh_token,
    redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    client_id: config.auth.clientId,
    client_secret: "",
  };

  return await axios
    .create({
      baseURL: config.auth.clientHost,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .post("/oauth2/token", payload)
    .then((response) => response.data);
};

export {
  appInfo,
  countCertificates,
  countKeys,
  fetchOAuthToken,
  getCertificateById,
  getCertificateByKey,
  getEncodedCertificateById,
  getEncodedKeyById,
  getKeyById,
  queryCertificates,
  queryIngestPipeline,
  queryIngestPipelineById,
  queryKeys,
  refreshOAuthToken,
  reparseCertificate,
  revalidateCertificate,
  saveCertificate,
  saveCertificateBlock,
};

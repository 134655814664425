import { IcAlert, IcButton, IcDataEntity, IcDataRow, IcHero, IcSectionContainer } from "@ukic/react";
import { useState } from "react";
import { useEffect } from "react";

import Header from "../../components/Header/Header";
import { useAuthorisationToken } from "../../context/AuthorisationProvider";
import { appInfo } from "../../utils/axiosRequests";

const HomePage = () => {
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const { token } = useAuthorisationToken();

  const fetchAppInfo = async () => {
    await appInfo(token)
      .then((data) => setInfo(data))
      .catch((e) => setError(`${e.name}. ${e.message}`));
  };

  useEffect(() => {
    fetchAppInfo();
  }, []);

  return (
    <div className="main-area" style={{ marginBottom: "24px" }}>
      <Header selected="home" />
      <IcHero
        heading="Welcome to the Certificate Store"
        subheading="This application provides a user interface for interacting with the Certificate Store API. 
                        The API connects to a store of X509 certificates and provides a suite of functionality to interact with them."
      >
        <IcButton variant="primary" slot="interaction" href="/certificates">
          Get Started
        </IcButton>
      </IcHero>
      {error && <IcAlert heading="Warning" message={error} variant="error" style={{ margin: "16px 24px" }} />}
      {info && (
        <>
          <IcSectionContainer>
            <IcDataEntity heading="App Info">
              <IcDataRow label="Total number of Certificates" value={info.database.certificateCount} />
              <IcDataRow label="Total number of Keys" value={info.database.keyCount} />
            </IcDataEntity>
          </IcSectionContainer>
          <IcSectionContainer>
            <IcDataEntity heading="Build Info">
              <IcDataRow label="Version" value={info.build.version} />
              <IcDataRow label="Time" value={new Date(info.build.time).toLocaleString("en-GB", { timeZone: "UTC" })} />
            </IcDataEntity>
          </IcSectionContainer>
          <IcSectionContainer>
            <IcDataEntity heading="Git Info">
              <IcDataRow label="Branch Name" value={info.git.branch} />
              <IcDataRow label="Commit ID" value={info.git.commit.id} />
              <IcDataRow
                label="Commit Time"
                value={new Date(info.git.commit.time).toLocaleString("en-GB", { timeZone: "UTC" })}
              />
            </IcDataEntity>
          </IcSectionContainer>
        </>
      )}
    </div>
  );
};

export default HomePage;
